import React, { useState, useRef, useEffect, useCallback } from "react";
import useClickOutside from "../../../../lib/hooks/useClickOutside";
import ButtonHeader from "./ButtonHeader";
import ZipInput from "../ZipInput";
import DropdownContainer from "./SelectDropdown/DropdownContainer";
import DropdownList from "./SelectDropdown/DropdownList";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/storeTypes";
import { hasSelectedItems, patchSelectList } from "../../../../lib/utils";
import {
    updateRadiusOptions,
    updateZipCode
} from "../../../../store/filters/actions";
import { updateActiveFilters } from "../../../../store/filters/actions";
import { fetchLocationIds } from "../../../../store/fetch";
import { SelectOption } from "../../../../lib/types";

const LocationDropdown: React.FC = () => {
    const dispatch = useDispatch();
    const locationDropdown = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [invalidZip, setInvalidZip] = useState<boolean>(true);
    const [touchedInput, setTouchedInput] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);
    const [updatedValues, setUpdatedValues] = useState<boolean>(false);

    const radiusOptions = useSelector(
        (state: RootState) => state.filters.radiusOptions
    );

    const activeFilters = useSelector(
        (state: RootState) => state.filters.activeFilters
    );

    const currZip = useSelector((state: RootState) => state.filters.zipCode);

    useClickOutside(locationDropdown, () => {
        setIsOpen(false);
        if (invalidZip) {
            dispatch(updateZipCode(""));
            setTouchedInput(false);
            setShowError(false);
            return;
        }
        updateFilters();
    });

    const updateFilters = () => {
        if (invalidZip || !hasSelectedItems(radiusOptions)) {
            setShowError(true);
            return;
        }
        if (!updatedValues) return;
        setTouchedInput(true);
        validateZip();

        setShowError(false);

        let selectRadius: SelectOption,
            newList: SelectOption[],
            filters: SelectOption[];

        selectRadius = radiusOptions.find(ro => ro.isSelected);
        filters = activeFilters.filter(
            af => !["distance", "zip"].includes(af.type)
        );

        newList = [
            ...filters,
            selectRadius,
            {
                type: "zip",
                value: currZip,
                label: currZip,
                isSelected: true
            }
        ];

        dispatch(fetchLocationIds(selectRadius.value, currZip));
        dispatch(updateActiveFilters(newList));
        setUpdatedValues(false);
    };

    const updateOptions = payload => {
        let updatedOptionList = patchSelectList(
            radiusOptions,
            payload.option,
            payload.isMulti
        );
        setUpdatedValues(true);
        dispatch(updateRadiusOptions(updatedOptionList));
    };

    const validateZip = useCallback(() => {
        const isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(currZip);
        if (isValidZip) {
            setInvalidZip(false);

            if (hasSelectedItems(radiusOptions)) {
                setShowError(false);
            }
        } else {
            setInvalidZip(true);
        }
    }, [currZip, radiusOptions]);

    useEffect(() => {
        validateZip();
    }, [validateZip]);

    // useEffect(() => {
    //     setUpdatedValues(true);
    // },[activeFilters])

    const handleTouched = () => {
        setTouchedInput(true);
    };

    const handleSubmission = () => {
        if (!invalidZip && hasSelectedItems(radiusOptions)) {
            setIsOpen(false);
            return;
        }

        updateFilters();
    };

    return (
        <div className="cpl-filter-dd-wrapper" ref={locationDropdown}>
            <ButtonHeader
                label="Filter By: "
                buttonLabel="Location"
                handleClick={e => setIsOpen(true)}
            />
            {isOpen && (
                <DropdownContainer>
                    <ZipInput
                        validateInput={validateZip}
                        invalidZip={invalidZip}
                        touched={touchedInput}
                        setTouched={handleTouched}
                        setUpdatedValues={setUpdatedValues}
                    />
                    <DropdownList
                        options={radiusOptions}
                        saveSelected={updateOptions}
                        isMulti={false}
                    />
                    {showError && (
                        <div className="cpl-error-msg">
                            Type in a zip code and pick a radius from the list.
                        </div>
                    )}
                    <button
                        className="cpl-save-selected"
                        // disabled={isDisabled}
                        onClick={e => handleSubmission()}
                    >
                        Save Selected
                    </button>
                </DropdownContainer>
            )}
        </div>
    );
};

export default LocationDropdown;
