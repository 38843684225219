import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateZipCode } from "../../../../store/filters/actions";
import { RootState } from "../../../../store/storeTypes";

type PropTypes = {
    validateInput: Function;
    setTouched: Function;
    setUpdatedValues: Function;
    invalidZip: boolean;
    touched: boolean;
    placeHolder?: string;
    customClass?: string;
};

const ZipInput: React.FC<PropTypes> = ({
    validateInput,
    invalidZip,
    touched,
    setTouched,
    setUpdatedValues,
    customClass = "cpl-zip-input",
    placeHolder = "Zip Code"
}) => {
    const dispatch = useDispatch();

    const currZip = useSelector((state: RootState) => state.filters.zipCode);

    useEffect(() => {
        if (touched) {
            validateInput();
        }
    }, [currZip, touched, validateInput]);

    const handleChange = val => {
        dispatch(updateZipCode(val));
        setUpdatedValues(true);
    };

    return (
        <div
            className={`${customClass} ${
                invalidZip && touched ? "cpl-zip-input--invalid" : ""
            }`}
        >
            <input
                type="text"
                id="userZipCode"
                onChange={e => handleChange(e.target.value)}
                onKeyPress={event =>
                    event.key === "Enter" ? setTouched() : null
                }
                onBlur={() => setTouched()}
                value={currZip}
                placeholder={placeHolder}
            />
        </div>
    );
};

export default ZipInput;
