import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { ReactComponent as MagnifyingGlass } from "../../../../assets/icons/findprovider-search-magnifying-glass.svg";
import useCheckMobileScreen from "../../../../lib/hooks/useCheckMobileScreen";
import { RootState } from "../../../../store/storeTypes";
import SpecialtyDropdownTextArea from "../CustomSelect/SpecialtyDropdownTextArea";

const SearchBySpecialty: React.FC = () => {
    const history = useHistory();
    const isMobile = useCheckMobileScreen();
    const searchLabel = useRef<HTMLParagraphElement>(null);
    const [showError, setShowError] = useState<boolean>(false);

    const specialties = useSelector(
        (state: RootState) => state.filters.specialties
    );

    const handleFocus = () => {
        if (isMobile) {
            searchLabel.current.scrollIntoView({
                behavior: "smooth"
            });
        }
    };

    const handleSubmission = () => {
        setTimeout(() => {
            let selected = specialties.some(sp => sp.isSelected);
            if (selected) {
                history.push({
                    pathname: "/results",
                    state: {
                        searchType: "specialty"
                    }
                });
            } else {
                setShowError(true);
            }
        }, 100);
    };

    return (
        <div className="cpl-form-wrapper cpl-form-wrapper-specialty">
            <h3>Providers By Specialty</h3>
            <p ref={searchLabel}>
                Select one or more specialty fields from the drop down below
            </p>
            <div className="cpl-search-by-specialty">
                <SpecialtyDropdownTextArea scrollTop={handleFocus} />
                <button
                    type="button"
                    onClick={handleSubmission}
                    className="cpl-button cpl-button--submit"
                >
                    <span>Search</span>
                    <MagnifyingGlass />
                </button>
            </div>
            {showError && (
                <p className="cpl-error">
                    Please select at least one specialty from the dropdown.
                </p>
            )}
        </div>
    );
};

export default SearchBySpecialty;
