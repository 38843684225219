import React from "react";

type LoaderProps = {
    customClass?: string;
};

const Loader: React.FC<LoaderProps> = ({ customClass }) => {
    return (
        <div className="loader-wrap">
            <div
                data-testid="loader"
                className={`loader ${customClass ? customClass : ""}`}
            >
                Loading...
            </div>
        </div>
    );
};

export default Loader;
