import { ActionType } from "../actionTypes";
import { ProviderTypes } from "./types";

export const updateProviderList = (providers: ProviderTypes[]) => {
    return {
        type: ActionType.UpdateProviderList,
        payload: {
            providers
        }
    };
};

export const updateGKey = (gKey: string) => {
    return {
        type: ActionType.UpdateGKey,
        payload: {
            gKey
        }
    };
};

export const updateCurrentAccounts = (currentAccounts: string[]) => {
    return {
        type: ActionType.UpdateProviderList,
        payload: {
            currentAccounts
        }
    };
};

export const fetchProvidersBegin = () => ({
    type: ActionType.FetchProvidersBegin,
    payload: null
});

export const fetchProvidersEnd = () => ({
    type: ActionType.FetchProvidersEnd,
    payload: null
});

export const fetchProvidersFailure = (error: string) => ({
    type: ActionType.FetchProvidersFailure,
    payload: { error }
});

export const updateNonce = (cuser: string) => ({
    type: ActionType.UpdateNonce,
    payload: { cuser }
});
