import React from "react";
import Loader from "../Loader";

type PropTypes = {
    message?: string;
    topAligned?: boolean;
};

const FullPageLoader: React.FC<PropTypes> = ({
    message,
    topAligned = false
}) => {
    return (
        <section
            className={`full-page-loader ${
                topAligned ? "full-page-loader--top-aligned" : ""
            }`}
        >
            <Loader />
        </section>
    );
};

export default FullPageLoader;
