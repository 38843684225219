import React, { useEffect, useRef, useState } from "react";
import { LocationTypes } from "../../../store/providers/types";
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker
} from "react-google-maps";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/storeTypes";

interface PropTypes {
    providerId: string;
    otherLocations: LocationTypes[];
    primaryLocation: LocationTypes;
}

const defaultMapOptions = {
    fullscreenControl: false,
    streetViewControl: false,
    scrollWheel: false,
    mapTypeControl: false,
    gestureHandling: "cooperative"
};
const labels = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

const MapComponent = withScriptjs(
    withGoogleMap((props: any) => {
        const mapRef = useRef(null);
        const handleZoomChange = () => {
            let newZoom = mapRef.current.getZoom();
            props.setZoom(newZoom);
        };
        return (
            <GoogleMap
                zoom={props.zoom}
                defaultCenter={props.center}
                defaultOptions={defaultMapOptions}
                onZoomChanged={handleZoomChange}
                ref={map => {
                    if (map) {
                        map.panTo(props.center);
                        mapRef.current = map;
                    }
                }}
            >
                {props.locations.map((l, i) => (
                    <Marker
                        key={"marker" + i}
                        position={{
                            lat: l.coordinates.lat,
                            lng: l.coordinates.long
                        }}
                        label={labels[i++ % labels.length]}
                    />
                ))}
            </GoogleMap>
        );
    })
);

const ProviderMap: React.FC<PropTypes> = ({
    providerId,
    primaryLocation,
    otherLocations
}) => {
    const [zoom, setZoom] = useState<number>(10);
    const key = useSelector((state: RootState) => state.providers.gKey);
    const mapApi = `https://maps.googleapis.com/maps/api/js?key=${key}`;

    useEffect(() => {
        setZoom(10);
    }, [providerId]);

    return (
        <div className="cpl-map-wrapper">
            <div className="cpl-provider-map">
                <MapComponent
                    zoom={zoom}
                    providerId={providerId}
                    googleMapURL={mapApi}
                    setZoom={setZoom}
                    locations={[primaryLocation, ...otherLocations]}
                    loadingElement={<div className="cpl-google-map" />}
                    containerElement={<div className="cpl-google-map" />}
                    mapElement={<div className="cpl-google-map" />}
                    center={{
                        lat: primaryLocation.coordinates.lat,
                        lng: primaryLocation.coordinates.long
                    }}
                />
            </div>

            <a
                className="cpl-external-link"
                target={"_blank"}
                href={encodeURI(
                    `https://www.google.com/maps/search/?api=1&query=${primaryLocation.streetAddress},${primaryLocation.city},${primaryLocation.state} ${primaryLocation.zip}`
                )}
            >
                Get Directions
            </a>
        </div>
    );
};

export default ProviderMap;
