import React from "react";
import { SelectOption } from "../../../../../lib/types";

type DropdownListItemTypes = {
    option: SelectOption;
    saveSelected: Function;
    isMulti?: boolean;
    stateName?: string;
};

const DropdownListItem: React.FC<DropdownListItemTypes> = ({
    saveSelected,
    option,
    isMulti = false,
    stateName
}) => {
    let selection = { option, isMulti, stateName };
    return (
        <li
            onClick={() => saveSelected(selection)}
            className={`cpl-filter-dd-select-option form-checkbox ${
                option.isSelected ? "cpl-filter-dd-select-option--selected" : ""
            }`}
        >
            {isMulti && (
                <input
                    type="checkbox"
                    checked={option.isSelected}
                    onChange={() => saveSelected(selection)}
                />
            )}
            <span> {option.label}</span>
        </li>
    );
};

export default DropdownListItem;
