import ZipInput from "../ZipInput";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/storeTypes";
import React, { useState, useRef, useEffect } from "react";
import { fetchLocationIds } from "../../../../store/fetch";
import useClickOutside from "../../../../lib/hooks/useClickOutside";
import DropdownList from "../CustomSelect/SelectDropdown/DropdownList";
import { hasSelectedItems, patchSelectList } from "../../../../lib/utils";
import { updateActiveFilters } from "../../../../store/filters/actions";
import useCheckMobileScreen from "../../../../lib/hooks/useCheckMobileScreen";
import { updateRadiusOptions } from "../../../../store/filters/actions";
import DropdownContainer from "../CustomSelect/SelectDropdown/DropdownContainer";
import { ReactComponent as ArrowDown } from "../../../../assets/icons/findprovider-arrow-down-open.svg";
import { ReactComponent as MagnifyingGlass } from "../../../../assets/icons/findprovider-search-magnifying-glass.svg";
import { SelectOption } from "../../../../lib/types";

const SearchByLocation: React.FC = () => {
    const isMobile = useCheckMobileScreen();
    const [updatedValues, setUpdatedValues] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);
    const [invalidZip, setInvalidZip] = useState<boolean>(true);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [selectedRadius, setSelectedRadius] = useState<SelectOption>();
    const [touchedInput, setTouchedInput] = useState<boolean>(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const locationDropdown = useRef<HTMLDivElement>(null);

    const radiusOptions = useSelector(
        (state: RootState) => state.filters.radiusOptions
    );
    const currZip = useSelector((state: RootState) => state.filters.zipCode);
    const activeFilters = useSelector(
        (state: RootState) => state.filters.activeFilters
    );

    useEffect(() => {
        let selected = radiusOptions.find(ro => ro.isSelected);
        setSelectedRadius(selected);
    }, [radiusOptions]);

    useClickOutside(locationDropdown, () => {
        setIsOpen(false);
    });

    const handleFocus = () => {
        if (isMobile) {
            locationDropdown.current.scrollIntoView({
                behavior: "smooth"
            });
        }
    };

    const handleSubmission = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        validateInput();
        if (invalidZip || !hasSelectedItems(radiusOptions)) {
            return;
        }

        if (!updatedValues) {
            return;
        }

        let selectRadius: SelectOption,
            newList: SelectOption[],
            filters: SelectOption[];

        selectRadius = radiusOptions.find(ro => ro.isSelected);
        filters = activeFilters.filter(
            af => !["distance", "zip"].includes(af.type)
        );

        newList = [
            ...filters,
            selectRadius,
            {
                type: "zip",
                value: currZip,
                label: currZip,
                isSelected: true
            }
        ];

        dispatch(fetchLocationIds(selectRadius.value, currZip));
        dispatch(updateActiveFilters(newList));
        // dispatch(fetchProviders(1));

        history.push({
            pathname: "/results",
            state: {
                searchType: "location"
            }
        });
    };

    const validateInput = () => {
        const isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(currZip);

        if (isValidZip) {
            setInvalidZip(false);

            if (hasSelectedItems(radiusOptions)) {
                setShowError(false);
            }
        } else {
            setInvalidZip(true);
            setShowError(true);
        }
    };

    const handleTouched = () => {
        setTouchedInput(true);
    };

    const updateOptions = payload => {
        let updatedOptionList = patchSelectList(
            radiusOptions,
            payload.option,
            payload.isMulti
        );
        setUpdatedValues(true);
        dispatch(updateRadiusOptions(updatedOptionList));
    };

    const toggleIsOpen = () => {
        setIsOpen(!isOpen);
        if (!isOpen) {
            handleFocus();
        }
    };

    return (
        <div className="cpl-form-wrapper cpl-form-wrapper-location">
            <h3>Providers By Location</h3>
            <form
                onSubmit={handleSubmission}
                className="cpl-search-by-location"
            >
                <div className="cpl-form-col cpl-form-col--wide">
                    <label htmlFor="userZipCode">
                        Find a provider near you. Type your zip code below to
                        get started.
                    </label>
                    <ZipInput
                        validateInput={validateInput}
                        invalidZip={invalidZip}
                        touched={touchedInput}
                        setTouched={handleTouched}
                        setUpdatedValues={setUpdatedValues}
                        customClass="cpl-location-input"
                        placeHolder="Enter your zip code"
                    />
                </div>
                <div
                    className="cpl-form-col cpl-form-dd-wrapper"
                    ref={locationDropdown}
                >
                    <p className="cpl-radius-label">Location Radius</p>
                    <button
                        className="cpl-location-head"
                        type="button"
                        onClick={() => toggleIsOpen()}
                    >
                        {selectedRadius && selectedRadius.label}
                        <ArrowDown />
                    </button>
                    {isOpen && (
                        <DropdownContainer>
                            <DropdownList
                                options={radiusOptions}
                                saveSelected={updateOptions}
                                isMulti={false}
                            />
                            <button
                                type="button"
                                className="cpl-save-selected"
                                onClick={toggleIsOpen}
                            >
                                Save Selected
                            </button>
                        </DropdownContainer>
                    )}
                </div>
                <div className="cpl-form-col cpl-form-col--search-button">
                    <button
                        type="submit"
                        className="cpl-button cpl-button--submit"
                    >
                        <span>Search</span>
                        <MagnifyingGlass />
                    </button>
                </div>
            </form>
            {showError && (
                <div className="cpl-error-msg">
                    Type in a valid zip code and pick a radius from the list.
                </div>
            )}
        </div>
    );
};

export default SearchByLocation;
