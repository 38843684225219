import React, { useState, useRef, useEffect } from "react";
import ResultsHeading from "../../components/SearchResults/ResultsHeading";
import ResultsStatusBar from "../../components/SearchResults/ResultsStatusBar";
import ActiveFiltersBar from "../../components/SearchResults/ActiveFiltersBar";
import SingleResult from "../../components/SearchResults/SingleResult";
import { RootState } from "../../store/storeTypes";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../components/Shared/Pagination/LocalPagination";
import { Link, useHistory } from "react-router-dom";
import { SelectOption } from "../../lib/types";
import {
    updateCurrentAccounts,
    updateProviderList
} from "../../store/providers/actions";
import {
    removeFilter,
    deselectDropdownItem,
    filterProviders,
    sortProviders
} from "../../lib/utils";
import {
    updateActiveFilters,
    updateSpecialties,
    updateRadiusOptions,
    updateLanguages
} from "../../store/filters/actions";
import FullPageLoader from "../../components/Shared/FullPageLoader";

const SearchResults: React.FC = () => {
    const dispatch = useDispatch();
    const locator = useRef<HTMLDivElement>(null);
    const history: Partial<any> = useHistory();
    const [searchType, setSearchType] = useState<string>("");
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [showFilters, setShowFilters] = useState<boolean>(false);

    const results = useSelector((state: RootState) =>
        state.providers.providers.filter(re => re.isVisible)
    );

    const providers = useSelector(
        (state: RootState) => state.providers.providers
    );

    const currAccounts = useSelector(
        (state: RootState) => state.providers.currentAccounts
    );

    const loading = useSelector((state: RootState) => state.providers.loading);

    // Retrieve State Data,
    // Active filters in Redux
    const activeFilters = useSelector(
        (state: RootState) => state.filters.activeFilters
    );
    const sortOptions = useSelector(
        (state: RootState) => state.filters.sortOptions
    );
    const specialtyOptions = useSelector(
        (state: RootState) => state.filters.specialties
    );
    const locationRadiusOptions = useSelector(
        (state: RootState) => state.filters.radiusOptions
    );
    const languages = useSelector(
        (state: RootState) => state.filters.languages
    );

    useEffect(() => {
        window.scroll(0, 0);
        let { state } = history.location;
        if (!state || !state.searchType) {
            history.push("/");
        } else if (state.searchType !== "all" && activeFilters.length === 0) {
            history.push("/");
        } else {
            setSearchType(state.searchType);
        }
    }, [
        history
        // activeFilters
    ]);

    useEffect(() => {
        let { length } = providers.filter(re => re.isVisible);
        if (length) {
            setShowFilters(true);
        }
    }, [providers]);

    useEffect(() => {
        let filtered = filterProviders(activeFilters, providers, currAccounts);
        dispatch(updateProviderList(filtered));
        setCurrentPage(1);
    }, [
        activeFilters,
        loading,
        currAccounts,
        sortOptions,
        languages
        // providers
    ]);

    const handleFilterRemoval = (item: SelectOption) => {
        let updatedFilters, updatedOptions;
        // Remove item from active filters
        updatedFilters = removeFilter(item, activeFilters);

        // Remove from select list
        switch (item.type) {
            case "specialty":
                updatedOptions = deselectDropdownItem(
                    item,
                    specialtyOptions,
                    false
                );
                dispatch(updateSpecialties(updatedOptions));
                break;
            case "language":
                updatedOptions = deselectDropdownItem(item, languages, false);
                dispatch(updateLanguages(updatedOptions));
                break;
            case "distance":
            case "zip":
                updatedFilters = activeFilters.filter(
                    af => !["zip", "distance"].includes(af.type)
                );
                updatedOptions = locationRadiusOptions.map(ro => ({
                    ...ro,
                    isSelected: ro.value === 5
                }));

                let selected = sortOptions.find(so => so.isSelected);
                let sorted = sortProviders(providers, selected.value);
                dispatch(updateCurrentAccounts([]));
                dispatch(updateRadiusOptions(updatedOptions));
                dispatch(updateProviderList(sorted));
                break;
            case "name":
                updatedFilters = activeFilters.filter(af => af.type !== "name");
                break;
            default:
                console.log(item);
                break;
        }

        dispatch(updateActiveFilters(updatedFilters));
    };

    const loadResults = () => {
        let activeResults = results;

        if (activeResults.length > 5) {
            activeResults = activeResults.filter((_, idx) => {
                let lastResIdx = currentPage * 5 - 1;
                let firstResIdx = lastResIdx - (5 - 1);

                return firstResIdx <= idx && idx <= lastResIdx;
            });
        }

        return activeResults.map((re, id) => <SingleResult key={id} {...re} />);
    };

    const noResults = () => {
        return (
            <div className="cpl-no-matches">
                No matches found. Please try again.
            </div>
        );
    };

    const handlePagination = (cp: number) => {
        locator.current.scrollIntoView();
        setCurrentPage(cp);
    };

    const renderResults = () => {
        return (
            <>
                <div className="cpl-results-list">
                    {results.length ? loadResults() : noResults()}
                </div>
                {results.length > 5 && (
                    <Pagination
                        totalItems={results.length}
                        pageSize={5}
                        currentPage={currentPage}
                        setCurrentPage={handlePagination}
                    />
                )}
                <Link
                    to="/"
                    className="cpl-button cpl-button--link cpl-button--lg"
                >
                    New Search
                </Link>
            </>
        );
    };

    return (
        <div className="cpl" ref={locator}>
            <div className="cpl-top-separator"></div>
            <div className="cpl-results-screen">
                <ResultsHeading searchType={searchType} />
                {showFilters && (
                    <>
                        <ResultsStatusBar
                            activeFilters={activeFilters}
                            totalResults={results.length}
                            removeFilter={handleFilterRemoval}
                            loading={loading}
                        />
                        <ActiveFiltersBar />
                    </>
                )}
                {loading ? (
                    <FullPageLoader topAligned={true} />
                ) : (
                    renderResults()
                )}
            </div>
        </div>
    );
};

export default SearchResults;
