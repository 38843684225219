import React from "react";
import LocationDropdown from "../../Shared/Forms/CustomSelect/LocationFilterDropdown";
import SpecialtyDropdown from "../../Shared/Forms/CustomSelect/SpecialtyFilterDropdown";
import SortOptionsDropdown from "../../Shared/Forms/CustomSelect/SortOptionsDropdown";
import LanguageFilterDropdown from "../../Shared/Forms/CustomSelect/LanguageFilterDropdown";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/storeTypes";

const ActiveFilterBar: React.FC = () => {
    const settings = useSelector((state: RootState) => state.settings);

    const displayLanguageFilter = settings?.languageFilter;

    return (
        <div className="cpl-refine-search-bar">
            <h4>Refine Your Search</h4>
            <div className="cpl-rsb-inner-wrapper">
                <SortOptionsDropdown />
                <SpecialtyDropdown />
                <LocationDropdown />
                {displayLanguageFilter && <LanguageFilterDropdown />}
            </div>
        </div>
    );
};

export default ActiveFilterBar;
