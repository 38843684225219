import React from "react";
import { Route, Switch, useLocation } from "react-router";
import { AnimatePresence } from "framer-motion";
import SearchLayout from "./SearchLayout";
import SearchResults from "./SearchResults";

const Routes: React.FC = () => {
    let location = useLocation();

    return (
        <AnimatePresence exitBeforeEnter initial={false}>
            <Switch key={location.pathname}>
                <Route
                    path="/"
                    exact
                    render={() => <SearchLayout selected="name" />}
                />
                <Route
                    path="/specialty"
                    exact
                    render={() => <SearchLayout selected="specialty" />}
                />
                <Route
                    path="/location"
                    exact
                    render={() => <SearchLayout selected="location" />}
                />
                <Route
                    path="/clinic"
                    exact
                    render={() => <SearchLayout selected="clinic" />}
                />
                <Route path="/results" exact render={() => <SearchResults />} />
            </Switch>
        </AnimatePresence>
    );
};

export default Routes;
