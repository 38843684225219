import { ActionType } from "../actionTypes";
import { SettingsAction, SettingsState } from "./types";

export const DefaultSettingsState = {
    languageFilter: false
};

export default function settings(
    state: SettingsState = DefaultSettingsState,
    action: SettingsAction
) {
    switch (action.type) {
        case ActionType.UpdateSettings:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}
