import { ActionType } from "../actionTypes";
import { FiltersState, FiltersAction } from "./types";
import {
    radiusOptions,
    sortOptions,
    specialties,
    languages
} from "./initialValues";

export const DefaultFiltersState = {
    activeFilters: [],
    zipCode: "",
    radiusOptions,
    specialties,
    sortOptions,
    languages
};

export default function filters(
    state: FiltersState = DefaultFiltersState,
    action: FiltersAction
) {
    switch (action.type) {
        case ActionType.UpdateActiveFilters:
            return {
                ...state,
                ...action.payload
            };
        case ActionType.UpdateRadiusOptions:
            return {
                ...state,
                ...action.payload
            };
        case ActionType.UpdateZipCode:
            return {
                ...state,
                zipCode: action.payload
            };
        case ActionType.UpdateSortOptions:
            return {
                ...state,
                ...action.payload
            };
        // Refactor to use this action everywhere
        case ActionType.UpdateFilterState:
            return {
                ...state,
                ...action.payload
            };
        case ActionType.UpdateSearchState:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}
