import React from "react";
import { ProviderTypes } from "../../../store/providers/types";
import ProviderDetail from "./ProviderDetail";
import ProviderMap from "./ProviderMap";

const SingleResult: React.FC<ProviderTypes> = ({ ...props }) => {
    return (
        <div className="cpl-single-result">
            <ProviderDetail {...props} />
            <ProviderMap
                primaryLocation={props.primaryLocation}
                otherLocations={props.otherLocations}
                providerId={props.providerId}
            />
        </div>
    );
};

export default SingleResult;
