import React, { useEffect, useState } from "react";
import { getPageNums } from "../../../lib/utils";
import { ReactComponent as ArrowLeftIcon } from "../../../assets/icons/findprovider-search-keyboard-left-arrow-button.svg";
import { ReactComponent as ArrowRightIcon } from "../../../assets/icons/findprovider-search-keyboard-right-arrow-button.svg";

type PropTypes = {
    pageSize: number;
    setCurrentPage: Function;
    currentPage: number;
    totalItems: number;
};

const Pagination: React.FC<PropTypes> = ({
    pageSize,
    totalItems,
    setCurrentPage,
    currentPage
}) => {
    const [numPages, setNumPages] = useState<number>(0);

    useEffect(() => {
        const numPages = Math.ceil(totalItems / Number(pageSize));
        setNumPages(numPages);
    }, [pageSize, totalItems, currentPage]);

    const renderPageNums = () => {
        const pageNums = getPageNums(numPages, currentPage);

        return pageNums.map((pageNum, idx) => {
            return (
                <button
                    type="button"
                    disabled={!Number.isInteger(pageNum)}
                    key={idx}
                    onClick={() => setCurrentPage(pageNum)}
                    className={`cpl-pagination-page-button ${
                        pageNum === currentPage
                            ? "cpl-pagination-page-button--active"
                            : ""
                    }`}
                >
                    <strong>{pageNum}</strong>
                </button>
            );
        });
    };

    return (
        <div className="cpl-pagination">
            <button
                type="button"
                data-testid="page-prev"
                className="cpl-pagination-nav-button"
                onClick={() => setCurrentPage(currentPage - 1)}
                disabled={currentPage === 1}
            >
                <ArrowLeftIcon />
            </button>
            {renderPageNums()}
            <button
                type="button"
                data-testid="page-next"
                onClick={() => setCurrentPage(currentPage + 1)}
                disabled={currentPage === numPages}
                className="cpl-pagination-nav-button"
            >
                <ArrowRightIcon />
            </button>
        </div>
    );
};

export default Pagination;
