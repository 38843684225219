import React from "react";
import { isSelected } from "../../../lib/utils";
import SearchByName from "../../Shared/Forms/SearchByName";
import SearchBySpecialty from "../../Shared/Forms/SearchBySpecialty";
import SearchByLocation from "../../Shared/Forms/SearchByLocation";
import SearchByClinic from "../../Shared/Forms/SearchByClinic/index";

type SearchFormsProps = {
    selected: string;
};

const SearchForms: React.FC<SearchFormsProps> = ({ selected }) => {
    // Render form based on selected button;

    return (
        <div className="sform-wrapper">
            {isSelected(selected, "name") && <SearchByName />}
            {isSelected(selected, "specialty") && <SearchBySpecialty />}
            {isSelected(selected, "location") && <SearchByLocation />}
            {isSelected(selected, "clinic") && <SearchByClinic />}
        </div>
    );
};

export default SearchForms;
