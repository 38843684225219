import React from "react";
import { createFilterLabel } from "../../../lib/utils";
import { SelectOption } from "../../../lib/types";
import { ReactComponent as CancelIcon } from "../../../assets/icons/findprovider-delete-icon.svg";

type PropTypes = {
    totalResults: number;
    activeFilters: SelectOption[];
    removeFilter: Function;
    loading: boolean;
};

const ResultsStatusBar: React.FC<PropTypes> = ({
    totalResults,
    activeFilters,
    removeFilter,
    loading
}) => {
    return (
        <div className="cpl-results-status-bar">
            <div className="cpl-status-column">
                {loading ? (
                    <span>Loading...</span>
                ) : (
                    <span>{totalResults} Results</span>
                )}
                {activeFilters.length > 0 && (
                    <>
                        <span>|</span>
                        <span>Searched For: </span>
                    </>
                )}
            </div>
            <div className="cpl-search-criteria">
                {activeFilters.map((item, id) => {
                    return (
                        <div className="cpl-filter" key={id}>
                            <button
                                onClick={e => removeFilter(item)}
                                className="cpl-rm-filter"
                                disabled={loading}
                            >
                                <CancelIcon />
                            </button>
                            <span>{`${createFilterLabel(item.type)} ${
                                item.label
                            }`}</span>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default ResultsStatusBar;
