export enum ActionType {
    UpdateSearchState = "UPDATE_SEARCH_STATE",
    UpdateFilterState = "UPDATE_FILTER_STATE",
    UpdateProviderState = "UPDATE_PROVIDER_STATE",
    UpdateActiveFilters = "UPDATE_FILTERS",
    UpdateProviderList = "UPDATE_PROVIDER_LIST",
    UpdateSortOptions = "UPDATE_SORT_OPTIONS",
    UpdateRadiusOptions = "UPDATE_RADIUS_OPTIONS",
    FetchProvidersBegin = "FETCH_PROVIDERS_BEGIN",
    FetchProvidersEnd = "FETCH_PROVIDERS_END",
    FetchProvidersFailure = "FETCH_PROVIDERS_FAILURE",
    UpdateNonce = "UPDATE_NONCE",
    UpdatePagination = "UPDATE_PAGINATION",
    UpdateZipCode = "UPDATE_ZIP_CODE",
    UpdateGKey = "UPDATE_G_KEY",
    UpdateSettings = "UPDATE_SETTINGS"
}
