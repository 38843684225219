import React, { useLayoutEffect, useEffect } from "react";
import Navigation from "../../components/Search/Navigation";
import SearchForm from "../../components/Search/SearchForms";
import { useDispatch, useSelector } from "react-redux";
import { updateActiveFilters } from "../../store/filters/actions";
import { updateSpecialties } from "../../store/filters/actions";
import { RootState } from "../../store/storeTypes";
import {
    updateRadiusOptions,
    updateZipCode
} from "../../store/filters/actions";
import {
    updateCurrentAccounts,
    updateProviderList
} from "../../store/providers/actions";
import { sortProviders } from "../../lib/utils";

type PropTypes = {
    selected: string;
};

const SearchLayout: React.FC<PropTypes> = ({ selected }) => {
    const dispatch = useDispatch();

    const specialties = useSelector(
        (state: RootState) => state.filters.specialties
    );
    const providers = useSelector(
        (state: RootState) => state.providers.providers
    );
    const radiusOptions = useSelector(
        (state: RootState) => state.filters.radiusOptions
    );
    const sortOptions = useSelector(
        (state: RootState) => state.filters.sortOptions
    );

    useEffect(() => {
        const selected = sortOptions.find(so => so.isSelected);
        const sorted = sortProviders(providers, selected.value);
        dispatch(updateProviderList(sorted));
    }, [providers]);

    useLayoutEffect(() => {
        window.scroll(0, 0);
        const specs = specialties.map(sp => ({ ...sp, isSelected: false }));
        const rOps = radiusOptions.map(ro => ({
            ...ro,
            isSelected: ro.value === 5
        }));

        dispatch(updateZipCode(""));
        dispatch(updateActiveFilters([]));
        dispatch(updateCurrentAccounts([]));
        dispatch(updateSpecialties(specs));
        dispatch(updateRadiusOptions(rOps));
    }, []);

    return (
        <div className="cpl">
            <Navigation selected={selected} />
            <SearchForm selected={selected} />
        </div>
    );
};

export default SearchLayout;
