import React, { useState, useRef, useEffect } from "react";
import useClickOutside from "../../../../lib/hooks/useClickOutside";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/storeTypes";
import { HandleSelectionTypes, SelectOption } from "../../../../lib/types";
import { filterOptionsByQuery, patchSelectList } from "../../../../lib/utils";
import { updateSpecialties } from "../../../../store/filters/actions";
import DropdownContainer from "./SelectDropdown/DropdownContainer";
import ButtonHeader from "./ButtonHeader";
import DropdownList from "./SelectDropdown/DropdownList";
import { updateActiveFilters } from "../../../../store/filters/actions";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/findprovider-delete-icon.svg";

const SpecialtyDropdown: React.FC = () => {
    const dispatch = useDispatch();
    const specialtyDropdown = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [query, setQuery] = useState<string>("");
    const [currentOptions, setCurrentOptions] = useState<SelectOption[]>([]);
    const [updatedValues, setUpdatedValues] = useState<boolean>(false);
    const specialties = useSelector(
        (state: RootState) => state.filters.specialties
    );
    const activeFilters = useSelector(
        (state: RootState) => state.filters.activeFilters
    );

    useEffect(() => {
        let filtered = filterOptionsByQuery(query, specialties);
        setCurrentOptions(filtered);
    }, [specialties, query]);

    useClickOutside(specialtyDropdown, () => {
        handleSubmission();
    });

    const updateFilters = () => {
        if (!updatedValues) return;
        let selectedSpecialties: SelectOption[],
            newList: SelectOption[],
            filters: SelectOption[];

        selectedSpecialties = specialties.filter(sp => sp.isSelected);
        filters = activeFilters.filter(sp => sp.type !== "specialty");
        newList = [...filters, ...selectedSpecialties];

        dispatch(updateActiveFilters(newList));
        setUpdatedValues(false);
    };

    const handleSubmission = () => {
        updateFilters();
        setIsOpen(false);
    };

    const updateOptions = (payload: HandleSelectionTypes) => {
        let updatedOptionList;

        updatedOptionList = patchSelectList(
            specialties,
            payload.option,
            payload.isMulti
        );

        dispatch(updateSpecialties(updatedOptionList));
        setUpdatedValues(true);
    };

    const filterSelectOptions = (query: string) => {
        let filtered = filterOptionsByQuery(query, specialties);
        setQuery(query);
        setCurrentOptions(filtered);
    };

    const clearQuery = e => {
        e.nativeEvent.stopImmediatePropagation();
        setQuery("");
    };

    const renderFinder = () => {
        return (
            <div className="cpl-select-wrapper">
                <input
                    type="text"
                    onChange={e => filterSelectOptions(e.target.value)}
                    value={query}
                    placeholder="Start typing a specialty..."
                />
                {query && query.length > 0 && (
                    <button
                        type="button"
                        className="cpl-specialty-rm-button"
                        onClick={clearQuery}
                    >
                        <DeleteIcon />
                    </button>
                )}
            </div>
        );
    };

    return (
        <div className="cpl-filter-dd-wrapper" ref={specialtyDropdown}>
            <ButtonHeader
                label="Filter By: "
                buttonLabel="Specialties"
                handleClick={e => setIsOpen(true)}
            />
            {isOpen && (
                <DropdownContainer>
                    {renderFinder()}
                    <DropdownList
                        options={currentOptions}
                        saveSelected={updateOptions}
                        isMulti={true}
                    />
                    <button
                        className="cpl-save-selected"
                        onClick={e => handleSubmission()}
                    >
                        Save Selected
                    </button>
                </DropdownContainer>
            )}
        </div>
    );
};

export default SpecialtyDropdown;
