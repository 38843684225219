import { ProvidersState, ProvidersAction } from "./types";
import { ActionType } from "../actionTypes";
// import { testSearchResults } from "./testData";

export const DefaultProvidersState = {
    providers: [],
    pagination: {
        totalItems: 0,
        hasNext: false,
        hasPrevious: false,
        totalPages: null,
        pageSize: null,
        pageIndex: null,
        showingAllResults: null
    },
    loading: true,
    error: null,
    cuser: null,
    currentAccounts: [],
    loadingAccounts: false,
    gKey: null,
    settings: {
        languageFilter: false
    }
};

export default function providers(
    state: ProvidersState = DefaultProvidersState,
    action: ProvidersAction
) {
    switch (action.type) {
        case ActionType.UpdateNonce:
            return {
                ...state,
                ...action.payload
            };
        case ActionType.UpdateGKey:
            return {
                ...state,
                ...action.payload
            };
        case ActionType.UpdateSettings:
            return {
                ...state,
                ...action.payload
            };
        case ActionType.UpdateProviderState:
            return {
                ...state,
                ...action.payload
            };
        case ActionType.UpdateProviderList:
            return {
                ...state,
                ...action.payload
            };
        case ActionType.UpdatePagination:
            return {
                ...state,
                ...action.payload
            };
        case ActionType.FetchProvidersBegin:
            return {
                ...state,
                loading: true,
                error: null
            };
        case ActionType.FetchProvidersEnd:
            return {
                ...state,
                loading: false
            };
        case ActionType.FetchProvidersFailure:
            return {
                ...state,
                providers: [],
                error: action.payload,
                loading: false
            };
        default:
            return state;
    }
}
