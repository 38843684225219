import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export type NavItemProps = {
    label: string;
    blueSVG: string;
    whiteSVG: string;
    selected: boolean;
    path: string;
};

const NavItem: React.FC<NavItemProps> = ({
    path,
    label,
    blueSVG,
    whiteSVG,
    selected
}) => {
    const [linkParams, setLinkParams] = useState<object>({});

    useEffect(() => {
        let params = {
            pathname: path,
            state: {}
        };

        if (path.includes("results")) {
            params = {
                ...params,
                state: {
                    searchType: "all"
                }
            };
        }

        setLinkParams(params);
    }, [path]);

    return (
        <Link
            to={linkParams}
            className={`cpl-nav-item ${selected ? " selected" : ""}`}
        >
            <img className="blue-svg" src={blueSVG} alt={label} />
            <img className="white-svg" src={whiteSVG} alt={label} />
            <span>{label}</span>
        </Link>
    );
};

export default NavItem;
