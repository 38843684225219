import { combineReducers } from "redux";
import providers from "./providers/reducers";
import filters from "./filters/reducers";
import settings from "./settings/reducers";

const rootReducer = combineReducers({
    providers,
    filters,
    settings
});

export default rootReducer;
