import { SelectOption } from "../../lib/types";

export const radiusOptions: SelectOption[] = [
    {
        value: 5,
        label: "5 Miles",
        isSelected: true,
        type: "distance"
    },
    {
        value: 10,
        label: "10 Miles",
        isSelected: false,
        type: "distance"
    },
    {
        value: 20,
        label: "20 Miles",
        isSelected: false,
        type: "distance"
    },
    {
        value: 50,
        label: "50 Miles",
        isSelected: false,
        type: "distance"
    }
];

export const sortOptions: SelectOption[] = [
    {
        value: "ASC",
        label: "Alphabetical (A-Z)",
        isSelected: true
    },
    {
        value: "DESC",
        label: "Alphabetical (Z-A)",
        isSelected: false
    }
];

export const specialties: SelectOption[] = [
    {
        value: "Allergy/Immunology",
        label: "Allergy/Immunology",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Anesthesiology",
        label: "Anesthesiology",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Audiologist",
        label: "Audiologist",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Cardiac Surgery",
        label: "Cardiac Surgery",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Cardiovascular Disease (Cardiology)",
        label: "Cardiovascular Disease (Cardiology)",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Certified Clinical Nurse Specialist",
        label: "Certified Clinical Nurse Specialist",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Certified Registered Nurse Anesthetist (CRNA)",
        label: "Certified Registered Nurse Anesthetist (CRNA)",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Chiropractor",
        label: "Chiropractor",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Clinical Cardiatric Electrophysiology",
        label: "Clinical Cardiatric Electrophysiology",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Clinical Neuropsychologist",
        label: "Clinical Neuropsychologist",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Colorectal Surgery (Proctology)",
        label: "Colorectal Surgery (Proctology)",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Critical Care (Intensivists)",
        label: "Critical Care (Intensivists)",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Dermatology",
        label: "Dermatology",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Diagnostic Radiology",
        label: "Diagnostic Radiology",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Emergency : Nursing Service Providers",
        label: "Emergency : Nursing Service Providers",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Emergency Medicine",
        label: "Emergency Medicine",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Endocrinology",
        label: "Endocrinology",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Family Practice",
        label: "Family Practice",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Gastroenterology",
        label: "Gastroenterology",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "General Practice",
        label: "General Practice",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "General Surgery",
        label: "General Surgery",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Geriatric Medicine",
        label: "Geriatric Medicine",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Gynecological Oncology",
        label: "Gynecological Oncology",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Hematology",
        label: "Hematology",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Hematology-Oncology",
        label: "Hematology-Oncology",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Hospice and Palliative Care",
        label: "Hospice and Palliative Care",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Hospitalist",
        label: "Hospitalist",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Infectious Disease",
        label: "Infectious Disease",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Internal Medicine",
        label: "Internal Medicine",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Interventional Cardiology",
        label: "Interventional Cardiology",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Interventional Pain Management",
        label: "Interventional Pain Management",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Interventional Radiology",
        label: "Interventional Radiology",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Licensed Clinical Social Worker",
        label: "Licensed Clinical Social Worker",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Medical Oncology",
        label: "Medical Oncology",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Nephrology",
        label: "Nephrology",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Neurology",
        label: "Neurology",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Neurosurgery",
        label: "Neurosurgery",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Nuclear Medicine",
        label: "Nuclear Medicine",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Nurse Practitioner",
        label: "Nurse Practitioner",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Obstetrics & Gynecology",
        label: "Obstetrics & Gynecology",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Occupational Therapist in Private Practice",
        label: "Occupational Therapist in Private Practice",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Ophthalmology",
        label: "Ophthalmology",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Optometry",
        label: "Optometry",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Orthopedic Surgery",
        label: "Orthopedic Surgery",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Otolaryngology",
        label: "Otolaryngology",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Pain Management",
        label: "Pain Management",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Pathology",
        label: "Pathology",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Pediatric Medicine",
        label: "Pediatric Medicine",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Physical Medicine and Rehabilitation",
        label: "Physical Medicine and Rehabilitation",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Physical Therapist in Private Practice",
        label: "Physical Therapist in Private Practice",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Physician Assistant",
        label: "Physician Assistant",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Physician, Sleep Medicine",
        label: "Physician, Sleep Medicine",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Plastic and Reconstructive Surgery",
        label: "Plastic and Reconstructive Surgery",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Podiatric Medicine",
        label: "Podiatric Medicine",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Preventive Medicine",
        label: "Preventive Medicine",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Psychiatry",
        label: "Psychiatry",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Psychologist, Clinical",
        label: "Psychologist, Clinical",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Pulmonary Disease",
        label: "Pulmonary Disease",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Radiation Oncology",
        label: "Radiation Oncology",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Registered Dietitian or Nutrition Professional",
        label: "Registered Dietitian or Nutrition Professional",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Registered Nurse",
        label: "Registered Nurse",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Rheumatology",
        label: "Rheumatology",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Social Worker",
        label: "Social Worker",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Speech Language Pathologist",
        label: "Speech Language Pathologist",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Sports Medicine",
        label: "Sports Medicine",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Surgical Oncology",
        label: "Surgical Oncology",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Thoracic Surgery",
        label: "Thoracic Surgery",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Urology",
        label: "Urology",
        isSelected: false,
        type: "specialty"
    },
    {
        value: "Vascular Surgery",
        label: "Vascular Surgery",
        isSelected: false,
        type: "specialty"
    }
];

export const languages: SelectOption[] = [
    {
        value: "English",
        label: "English",
        isSelected: false,
        type: "language"
    },
    {
        value: "Spanish",
        label: "Spanish",
        isSelected: false,
        type: "language"
    }
];
