import React, { useState, useRef, useEffect } from "react";
import useClickOutside from "../../../../lib/hooks/useClickOutside";
import { ReactComponent as ArrowDown } from "../../../../assets/icons/findprovider-arrow-down-open.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/findprovider-delete-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/storeTypes";
import { HandleSelectionTypes, SelectOption } from "../../../../lib/types";
import { filterOptionsByQuery, patchSelectList } from "../../../../lib/utils";
import { updateSpecialties } from "../../../../store/filters/actions";
import DropdownContainer from "./SelectDropdown/DropdownContainer";
import DropdownList from "./SelectDropdown/DropdownList";
import { updateActiveFilters } from "../../../../store/filters/actions";

type PropTypes = {
    scrollTop?: Function;
};

const SpecialtyDropdownTextArea: React.FC<PropTypes> = ({ scrollTop }) => {
    const dispatch = useDispatch();
    const specialtyDropdownTextArea = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [query, setQuery] = useState<string>("");
    const [currentOptions, setCurrentOptions] = useState<SelectOption[]>([]);
    const [updatedValues, setUpdatedValues] = useState<boolean>(false);
    const specialties = useSelector(
        (state: RootState) => state.filters.specialties
    );
    const activeFilters = useSelector(
        (state: RootState) => state.filters.activeFilters
    );

    useEffect(() => {
        let filtered = filterOptionsByQuery(query, specialties);
        setCurrentOptions(filtered);
    }, [specialties, query]);

    useClickOutside(specialtyDropdownTextArea, () => {
        handleSubmission();
    });

    const updateFilters = () => {
        if (!updatedValues) return;
        let selectedSpecialties: SelectOption[],
            newList: SelectOption[],
            filters: SelectOption[];

        selectedSpecialties = specialties.filter(sp => sp.isSelected);
        filters = activeFilters.filter(sp => sp.type !== "specialty");
        newList = [...filters, ...selectedSpecialties];

        dispatch(updateActiveFilters(newList));
        setUpdatedValues(false);
    };

    const handleSubmission = () => {
        updateFilters();
        setIsOpen(false);
    };

    const updateOptions = (payload: HandleSelectionTypes) => {
        let updatedOptionList;

        updatedOptionList = patchSelectList(
            specialties,
            payload.option,
            payload.isMulti
        );

        dispatch(updateSpecialties(updatedOptionList));
        setUpdatedValues(true);
    };

    const clearQuery = e => {
        e.nativeEvent.stopImmediatePropagation();
        setQuery("");
    };

    const filterSelectOptions = (query: string) => {
        let filtered = filterOptionsByQuery(query, specialties);
        setQuery(query);
        setCurrentOptions(filtered);
    };

    const renderFinder = () => {
        return (
            <div className="cpl-select-wrapper">
                <input
                    type="text"
                    onChange={e => filterSelectOptions(e.target.value)}
                    value={query}
                    placeholder="Start typing a specialty..."
                />
                {query && query.length > 0 && (
                    <button
                        type="button"
                        className="cpl-specialty-rm-button"
                        onClick={clearQuery}
                    >
                        <DeleteIcon />
                    </button>
                )}
            </div>
        );
    };

    const toggleOpen = () => {
        setIsOpen(!isOpen);

        if (scrollTop) {
            scrollTop();
        }
    };

    const renderItemContainer = () => {
        return (
            <div onClick={e => toggleOpen()} className="cpl-item-container">
                <div>
                    {specialties
                        .filter(o => o.isSelected)
                        .map((o, id, arr) => {
                            return (
                                <div className="cpl-filter" key={id}>
                                    <span>{`${o.label}${
                                        id < arr.length - 1 ? ", " : " "
                                    }`}</span>
                                </div>
                            );
                        })}
                    {specialties.filter(o => o.isSelected).length === 0 && (
                        <span>Select a specialty</span>
                    )}
                </div>
                <button
                    type="button"
                    className="cpl-dd-button"
                    onClick={e => setIsOpen(true)}
                >
                    <ArrowDown />
                </button>
            </div>
        );
    };
    return (
        <div className="cpl-filter-dd-wrapper" ref={specialtyDropdownTextArea}>
            {renderItemContainer()}
            {isOpen && (
                <DropdownContainer>
                    {renderFinder()}
                    <DropdownList
                        options={currentOptions}
                        saveSelected={updateOptions}
                        isMulti={true}
                    />
                    <button
                        type="button"
                        className="cpl-save-selected"
                        onClick={handleSubmission}
                    >
                        Save Selected
                    </button>
                </DropdownContainer>
            )}
        </div>
    );
};

export default SpecialtyDropdownTextArea;
