import React from "react";
import DropdownListItem from "./DropdownListItem";
import { SelectOption } from "../../../../../lib/types";

type DropdownListTypes = {
    options: SelectOption[];
    saveSelected: Function;
    isMulti?: boolean;
    stateName?: string;
};
const DropdownList: React.FC<DropdownListTypes> = ({ options, ...props }) => {
    return (
        <ul className="cpl-filter-dd-select-options">
            {options.map((o, id) => (
                <DropdownListItem key={id} option={o} {...props} />
            ))}
        </ul>
    );
};

export default DropdownList;
