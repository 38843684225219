import React from "react";
import { createHeading } from "../../../lib/utils";
import { Link } from "react-router-dom";

type PropTypes = {
    searchType: string;
};

const ResultsHeading: React.FC<PropTypes> = ({ searchType }) => {
    return (
        <div className="cpl-results-heading">
            <h3>{createHeading(searchType)}</h3>
            <Link to="/" className="cpl-button cpl-button--link cpl-button--sm">
                New Search
            </Link>
        </div>
    );
};

export default ResultsHeading;
