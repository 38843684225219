import { RootState, ThunkResult } from "./storeTypes";
import {
    fetchProvidersBegin,
    fetchProvidersEnd,
    fetchProvidersFailure,
    updateCurrentAccounts
} from "./providers/actions";

const headers = {
    Accept: "application/json",
    "Content-Type": "application/json"
};

const fetchHandler = (url: string, options) => {
    return fetch(url, options).then(r => r.json());
};

const fetchPost = (callUrl: string, cuser: string, data) => {
    let callHeaders = {
        ...headers,
        "X-WP-Nonce": cuser
    };

    let options = {
        headers: callHeaders,
        credentials: "include",
        method: "POST",
        body: JSON.stringify(data)
    };

    return fetchHandler(callUrl, options);
};

export const fetchLocationIds = (distance, zip): ThunkResult<void> => async (
    dispatch,
    getState
) => {
    let state: RootState = getState();

    let {
        providers: { cuser }
    } = state;

    let data = {
        distance,
        zip
    };
    dispatch(fetchProvidersBegin());

    try {
        let res = await fetchPost(
            "/wp-json/centrus-provider-locator/v2/locations",
            cuser,
            data
        );
        if (!res.success) {
            throw new Error("There was an issue connecting to Salesforce");
        } else {
            dispatch(updateCurrentAccounts(res.accounts));
            dispatch(fetchProvidersEnd());
        }
    } catch (e) {
        dispatch(fetchProvidersFailure(e));
        dispatch(fetchProvidersEnd());
    }
};
