import { ActionType } from "../actionTypes";
import { SelectOption } from "../../lib/types";

export const updateSpecialties = (specialties: SelectOption[]) => {
    return {
        type: ActionType.UpdateFilterState,
        payload: {
            specialties
        }
    };
};

export const updateSortOptions = (sortOptions: SelectOption[]) => {
    return {
        type: ActionType.UpdateSortOptions,
        payload: {
            sortOptions
        }
    };
};

export const updateRadiusOptions = (radiusOptions: SelectOption[]) => {
    return {
        type: ActionType.UpdateRadiusOptions,
        payload: {
            radiusOptions
        }
    };
};

export const updateZipCode = (zipCode: string) => {
    return {
        type: ActionType.UpdateRadiusOptions,
        payload: {
            zipCode
        }
    };
};

export const updateActiveFilters = (activeFilters: SelectOption[]) => {
    return {
        type: ActionType.UpdateActiveFilters,
        payload: {
            activeFilters
        }
    };
};

export const updateLanguages = (languages: SelectOption[]) => {
    return {
        type: ActionType.UpdateFilterState,
        payload: {
            languages
        }
    };
};
