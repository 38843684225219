import React, { useState, useRef, useEffect } from "react";
import ButtonHeader from "./ButtonHeader";
import { useDispatch, useSelector } from "react-redux";
import { patchSelectList, sortProviders } from "../../../../lib/utils";
import DropdownList from "./SelectDropdown/DropdownList";
import { RootState } from "../../../../store/storeTypes";
import DropdownContainer from "./SelectDropdown/DropdownContainer";
import useClickOutside from "../../../../lib/hooks/useClickOutside";
import { updateProviderList } from "../../../../store/providers/actions";
import { updateSortOptions } from "../../../../store/filters/actions";

const SortOptionsDropdown: React.FC = () => {
    const dispatch = useDispatch();
    const sortDropdown = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [updatedValues, setUpdatedValues] = useState<boolean>(false);
    const [selectedLabel, setSelectedLabel] = useState<string>("");

    const sortOptions = useSelector(
        (state: RootState) => state.filters.sortOptions
    );
    const providers = useSelector(
        (state: RootState) => state.providers.providers
    );

    useEffect(() => {
        let selected = sortOptions.find(so => so.isSelected);
        setSelectedLabel(selected.label);
    }, [sortOptions]);

    useClickOutside(sortDropdown, () => {
        handleSubmission();
    });

    const updateOptions = payload => {
        let updatedOptionList = patchSelectList(
            sortOptions,
            payload.option,
            payload.isMulti
        );
        setUpdatedValues(true);
        dispatch(updateSortOptions(updatedOptionList));
    };

    const handleSubmission = () => {
        let selected = sortOptions.find(so => so.isSelected);
        setIsOpen(false);
        if (!updatedValues) return;
        // sort results
        let sorted = sortProviders(providers, selected.value);
        dispatch(updateProviderList(sorted));
        setUpdatedValues(false);
    };

    return (
        <div className="cpl-filter-dd-wrapper" ref={sortDropdown}>
            <ButtonHeader
                label="Sort By: "
                buttonLabel={selectedLabel}
                handleClick={e => setIsOpen(true)}
            />
            {isOpen && (
                <DropdownContainer>
                    <DropdownList
                        options={sortOptions}
                        saveSelected={updateOptions}
                        isMulti={false}
                    />
                    <button
                        className="cpl-save-selected"
                        onClick={e => handleSubmission()}
                    >
                        Save Selected
                    </button>
                </DropdownContainer>
            )}
        </div>
    );
};

export default SortOptionsDropdown;
