import { DataTypes } from "../App";
import { SelectOption } from "./types";
import { ProviderTypes } from "../store/providers/types";

export const isSelected = (selected: string, current: string) =>
    selected === current;

export const createHeading = (type: string) => {
    let heading;
    switch (type) {
        case "name":
            heading = "Providers By Name";
            break;
        case "specialty":
            heading = "Providers By Specialty";
            break;
        case "location":
            heading = "Providers By Location";
            break;
        case "clinic":
            heading = "Providers By Clinic";
            break;
        default:
            heading = "All Providers";
            break;
    }

    return heading;
};

export const createFilterLabel = (type: string) => {
    switch (type) {
        case "zip":
            return "Zip Code: ";
        case "name":
            return "Name: ";
        case "distance":
            return "Within";
        case "clinic":
            return "Clinic: ";
        default:
            return "";
    }
};

export const patchSelectList = (
    options: SelectOption[],
    option: SelectOption,
    isMulti: boolean
) => {
    if (!isMulti) {
        return options.map(sp => ({
            ...sp,
            isSelected: sp.value === option.value ? true : false
        }));
    } else {
        return options.map(sp =>
            sp.value === option.value
                ? { ...sp, isSelected: !option.isSelected }
                : sp
        );
    }
};

export const patchActiveFilters = (
    activeFilters: SelectOption[],
    updatedList: SelectOption[],
    option: SelectOption,
    isMulti
) => {
    // remove all of given type
    let selected = updatedList.filter(o => o.isSelected);
    activeFilters = activeFilters.filter(o => o.type !== option.type);

    return [...activeFilters, ...selected];
};

export const removeFilter = (filter: SelectOption, filters: SelectOption[]) => {
    return filters.filter(ft => ft.value !== filter.value);
};

export const deselectDropdownItem = (
    item: SelectOption,
    items: SelectOption[],
    isSelected: boolean
) => {
    return items.map(o => (o.value === item.value ? { ...o, isSelected } : o));
};

export const filterResultsBySpecialty = (
    results: ProviderTypes[],
    specialties: SelectOption[]
) => {
    let specs = specialties.map(sp => sp.value);
    let filtered = results.map(res => ({
        ...res,
        isVisible: specs.includes(res.primarySpecialty)
    }));
    return filtered;
};

export const filterResultsByName = (
    results: ProviderTypes[],
    query: string
) => {
    return results.filter(res => {
        let lastName = res.lastName.toLowerCase();
        let firstName = res.firstName.toLowerCase();
        let fullName = `${firstName} ${lastName}`;
        query = query.trim().toLowerCase();
        return fullName.includes(query);
    });
};

export const filterResultsByClinic = (
    results: ProviderTypes[],
    query: string
) => {
    return results.filter(res => {
        let clinicName = res.primaryLocation?.locationName?.toLowerCase();
        query = query.trim().toLowerCase();

        return clinicName.includes(query);
    });
};

export const filterOptionsByQuery = (
    query: string,
    options: SelectOption[]
) => {
    let filtered = options.filter(o =>
        o.label.toLowerCase().includes(query.toLowerCase())
    );

    return filtered;
};

export const getProvidersByName = (results: ProviderTypes[], query: string) => {
    let filtered = results.map(res => {
        let fullName = `${res.firstName} ${res.lastName}`.toLowerCase();
        query = query.toLowerCase();
        return {
            ...res,
            isVisible: fullName.includes(query)
        };
    });
    return filtered;
};

export const loadVisibleResults = (items: ProviderTypes[]) =>
    items.filter(res => res.isVisible);

export const retrieveFiltersByType = (items: SelectOption[], type: string) =>
    items.filter(af => af.type === type);

export const markAsVisible = (items: ProviderTypes[]) => {
    return items.map(res => ({ ...res, isVisible: true }));
};

export const formatPhoneNumber = (number: string) => {
    let cleaned = ("" + number).replace(/\D/g, "");
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
};

export const embolden = (
    firstName: string,
    lastName: string,
    sub: string
): string => {
    sub = sub.trim();
    let fullName = `${firstName} ${lastName}`;
    let reg = new RegExp(sub, "gi");
    let formatted = fullName.replace(reg, str => str.bold());

    return formatted;
};

export const hasSelectedItems = (options: SelectOption[]) => {
    return options.some(o => o.isSelected);
};

export const parseInitialData = () => {
    let dataEl = document.querySelector("#cpl-providers");
    let data,
        providers,
        languages = [];
    let values: DataTypes = {
        providers: [],
        cuser: null,
        locations: [],
        gKey: null,
        languages: [],
        settings: null
    };

    try {
        data = JSON.parse(dataEl.innerHTML);
        if (data.providers && data.providers.length) {
            providers = data.providers.reduce((acc, cur) => {
                if (cur.language && !languages.includes(cur.language)) {
                    languages.push(cur.language);
                }

                return [
                    ...acc,
                    {
                        ...cur,
                        // transform otherlocations array of account ids into an array of location objects for display
                        otherLocations: cur.otherLocations.reduce((a, c) => {
                            // Make sure there are no repeated items
                            let location = data.locations.find(
                                l => l.locationId === c
                            );

                            if (location && !a.includes(location)) {
                                a = a.concat(location);
                            }

                            return a;
                        }, [])
                    }
                ];
            }, []);

            values = {
                ...data,
                providers,
                languages
            };
        }
    } catch (e) {
        console.error("unable to parse enrollment data.", e);
    }

    return values;
};

export const checkIfWithinRadius = (
    provider: ProviderTypes,
    accounts: string[]
) => {
    let locations = [...provider.otherLocations, provider.primaryLocation];

    return locations
        .map(loc => loc.locationId)
        .some(locId => accounts.includes(locId));
};

export const filterProviders = (filters, providers, accounts = []) => {
    // TODO: Clean this up
    let visibleResults;
    let specialties = filters
        .filter(af => af.type === "specialty")
        .map(af => af.value);
    let name = filters.find(af => af.type === "name");
    let clinic = filters.find(af => af.type === "clinic");
    let clinicSearch = clinic?.label?.toLowerCase();
    let languages = filters
        .filter(af => af.type === "language")
        .map(af => af.value);

    visibleResults = providers.map(af => {
        let res = af;
        let providerName = `${res.firstName} ${res.lastName}`.toLowerCase();
        let clinicName = res.primaryLocation?.locationName.toLowerCase();
        let matchesLanguage = true;
        let matchesClinic = true;

        let hasSpecialties = specialties.length
            ? specialties.includes(res.primarySpecialty)
            : true;
        let isWithinRadius = accounts.length
            ? checkIfWithinRadius(res, accounts)
            : true;
        let matchesName = true;

        if (name) {
            matchesName = providerName.includes(name.label.toLowerCase());
        }

        if (clinic && clinicSearch) {
            matchesClinic = clinicName.includes(clinicSearch);

            if (!matchesClinic && res.otherLocations.length) {
                for (let i = 0; i < res.otherLocations.length; i++) {
                    if (
                        res.otherLocations[i]?.locationName
                            ?.toLowerCase()
                            .includes(clinicSearch)
                    ) {
                        matchesClinic = true;
                        break;
                    }
                }
            }
        }

        if (languages.length) {
            matchesLanguage = languages.includes(res.language);
        }

        res.isVisible =
            hasSpecialties &&
            matchesName &&
            isWithinRadius &&
            matchesLanguage &&
            matchesClinic;
        return res;
    });

    if (filters.length && accounts.length) {
        let inLocations = accounts.reduce((acc, cur) => {
            let vals = visibleResults.filter(
                re => re.primaryLocation.locationId === cur && !acc.includes(re)
            );
            acc = acc.concat(vals);
            return acc;
        }, []);

        visibleResults = visibleResults.reduce(
            (acc, cur) => {
                if (!cur.isVisible && !acc.includes(cur)) {
                    acc = acc.concat(cur);
                }

                return acc;
            },
            [...inLocations]
        );
    }

    if (!accounts.length && filters.some(ft => ft.type === "zip")) {
        visibleResults = providers.map(re => ({ ...re, isVisible: false }));
    }
    return visibleResults;
};

export const getPageNums = (totalPages, page) => {
    let maxNumOfPages;

    switch (true) {
        case window.innerWidth < 800:
            maxNumOfPages = 6;
            break;
        case window.innerWidth > 800 && window.innerWidth < 1200:
            maxNumOfPages = 6;
            break;
        case window.innerWidth > 1200:
            maxNumOfPages = 7;
            break;
        default:
            maxNumOfPages = 7;
            break;
    }

    const range = (start, end) => {
        return Array.from(Array(end - start + 1), (_, i) => i + start);
    };

    var sideWidth = maxNumOfPages < 9 ? 1 : 2;
    var leftWidth = (maxNumOfPages - sideWidth * 2 - 3) >> 1;
    var rightWidth = (maxNumOfPages - sideWidth * 2 - 2) >> 1;
    if (totalPages <= maxNumOfPages) {
        // no breaks in list
        return range(1, totalPages);
    }
    if (page <= maxNumOfPages - sideWidth - 1 - rightWidth) {
        // no break on left of page
        return range(1, maxNumOfPages - sideWidth - 1).concat(
            "...",
            range(totalPages - sideWidth + 1, totalPages)
        );
    }
    if (page >= totalPages - sideWidth - 1 - rightWidth) {
        // no break on right of page
        return range(1, sideWidth).concat(
            "...",
            range(
                totalPages - sideWidth - 1 - rightWidth - leftWidth,
                totalPages
            )
        );
    }
    // Breaks on both sides
    return range(1, sideWidth).concat(
        "...",
        range(page - leftWidth, page + rightWidth),
        "...",
        range(totalPages - sideWidth + 1, totalPages)
    );
};

export const sortProviders = (
    providers: ProviderTypes[],
    sortBy: string | number
) => {
    return providers.sort((a, b) => {
        let firstProviderName = `${a.lastName} ${a.firstName}`;
        let secondProviderName = `${b.lastName} ${b.firstName}`;
        return sortBy === "ASC"
            ? firstProviderName.localeCompare(secondProviderName)
            : secondProviderName.localeCompare(firstProviderName);
    });
};
