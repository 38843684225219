import React from "react";

const DropdownContainer: React.FC = ({ children }) => {
    return (
        <div className="cpl-filter-dd-container">
            <div className="cpl-filter-dd-select">{children}</div>
        </div>
    );
};

export default DropdownContainer;
