import React, { Suspense, useEffect } from "react";
import { Provider, useDispatch } from "react-redux";
import { store } from "./store";
import FullPageLoader from "./components/Shared/FullPageLoader";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./screens/Routes";
import { parseInitialData } from "./lib/utils";
import {
    fetchProvidersEnd,
    updateGKey,
    updateNonce,
    updateProviderList
} from "./store/providers/actions";
import { ProviderTypes, LocationTypes } from "./store/providers/types";
import { updateLanguages } from "./store/filters/actions";
import { SettingsState } from "./store/settings/types";
import { updateSettings } from "./store/settings/actions";

export type DataTypes = {
    cuser: string;
    providers: ProviderTypes[];
    locations: LocationTypes[];
    gKey: string;
    languages: string[];
    settings: SettingsState;
};

const AppRouter: React.FC = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        let data = parseInitialData();
        dispatch(updateNonce(data.cuser));
        dispatch(updateProviderList(data.providers));
        dispatch(updateGKey(data.gKey));
        dispatch(fetchProvidersEnd());
        dispatch(updateSettings(data.settings));

        dispatch(
            updateLanguages(
                data.languages?.map(lang => ({
                    value: lang,
                    label: lang,
                    isSelected: false,
                    type: "language"
                }))
            )
        );
    }, [dispatch]);

    return (
        <Router basename="find-a-provider">
            <Suspense fallback={<FullPageLoader />}>
                <Routes />
            </Suspense>
        </Router>
    );
};

const App: React.FC = () => {
    return (
        <Provider store={store}>
            <AppRouter />
        </Provider>
    );
};

export default App;
