import { ActionType } from "../actionTypes";
import { SettingsState } from "./types";

export const updateSettings = (settings: SettingsState) => {
    return {
        type: ActionType.UpdateSettings,
        payload: {
            ...settings
        }
    };
};
