import React, { useState, useRef, useEffect } from "react";
import ButtonHeader from "./ButtonHeader";
import { useDispatch, useSelector } from "react-redux";
import { patchSelectList, sortProviders } from "../../../../lib/utils";
import DropdownList from "./SelectDropdown/DropdownList";
import { RootState } from "../../../../store/storeTypes";
import DropdownContainer from "./SelectDropdown/DropdownContainer";
import useClickOutside from "../../../../lib/hooks/useClickOutside";
import {
    updateActiveFilters,
    updateLanguages
} from "../../../../store/filters/actions";
import { SelectOption } from "../../../../lib/types";

const LanguageFilterDropdown: React.FC = () => {
    const dispatch = useDispatch();
    const sortDropdown = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [updatedValues, setUpdatedValues] = useState<boolean>(false);

    const languages = useSelector(
        (state: RootState) => state.filters.languages
    );
    const providers = useSelector(
        (state: RootState) => state.providers.providers
    );
    const activeFilters = useSelector(
        (state: RootState) => state.filters.activeFilters
    );

    useClickOutside(sortDropdown, () => {
        handleSubmission();
    });

    const updateOptions = payload => {
        let updatedOptionList = patchSelectList(
            languages,
            payload.option,
            payload.isMulti
        );
        setUpdatedValues(true);
        dispatch(updateLanguages(updatedOptionList));
    };

    const updateFilters = () => {
        if (!updatedValues) return;
        let selectedLanguages: SelectOption[],
            newList: SelectOption[],
            filters: SelectOption[];

        selectedLanguages = languages.filter(sp => sp.isSelected);
        filters = activeFilters.filter(sp => sp.type !== "language");
        newList = [...filters, ...selectedLanguages];

        dispatch(updateActiveFilters(newList));
        setUpdatedValues(false);
    };

    const handleSubmission = () => {
        updateFilters();
        setIsOpen(false);
    };

    return (
        <div className="cpl-filter-dd-wrapper" ref={sortDropdown}>
            <ButtonHeader
                label="Filter By: "
                buttonLabel="Language"
                handleClick={e => setIsOpen(true)}
            />
            {isOpen && (
                <DropdownContainer>
                    <DropdownList
                        options={languages}
                        saveSelected={updateOptions}
                        isMulti={true}
                    />
                    <button
                        className="cpl-save-selected"
                        onClick={e => handleSubmission()}
                    >
                        Save Selected
                    </button>
                </DropdownContainer>
            )}
        </div>
    );
};

export default LanguageFilterDropdown;
