import React from "react";
import NavItem, { NavItemProps } from "./NavItem";
import BlueNameIcon from "../../../assets/icons/findprovider-search-name-icon-blue.svg";
import BlueSpecialtyIcon from "../../../assets/icons/findprovider-search-specialty-icon-blue.svg";
import BlueLocationIcon from "../../../assets/icons/findprovider-search-location-icon-blue.svg";
import BlueSearchAllIcon from "../../../assets/icons/findprovider-search-all-icon-blue.svg";
import WhiteNameIcon from "../../../assets/icons/findprovider-search-name-icon-white.svg";
import WhiteSpecialtyIcon from "../../../assets/icons/findprovider-search-specialty-icon-white.svg";
import WhiteLocationIcon from "../../../assets/icons/findprovider-search-location-icon-white.svg";
import WhiteSearchAllIcon from "../../../assets/icons/findprovider-search-all-icon-white.svg";
import BlueClinicIcon from "../../../assets/icons/findprovider-search-clinic-icon-blue.svg";
import WhiteClinicIcon from "../../../assets/icons/findprovider-search-clinic-icon-blue.svg";
import { isSelected } from "../../../lib/utils";

type NavigationProps = {
    selected: string;
};

const Navigation: React.FC<NavigationProps> = ({ selected }) => {
    const navItems: NavItemProps[] = [
        {
            path: "/",
            label: "Search By Name",
            blueSVG: BlueNameIcon,
            whiteSVG: WhiteNameIcon,
            selected: isSelected(selected, "name")
        },
        {
            path: "/specialty",
            label: "Search By Specialty",
            blueSVG: BlueSpecialtyIcon,
            whiteSVG: WhiteSpecialtyIcon,
            selected: isSelected(selected, "specialty")
        },
        {
            path: "/location",
            label: "Search By Location",
            blueSVG: BlueLocationIcon,
            whiteSVG: WhiteLocationIcon,
            selected: isSelected(selected, "location")
        },
        {
            path: "/clinic",
            label: "Search By Clinic",
            blueSVG: BlueClinicIcon,
            whiteSVG: WhiteClinicIcon,
            selected: isSelected(selected, "clinic")
        },
        {
            path: "/results",
            label: "Search All Providers",
            blueSVG: BlueSearchAllIcon,
            whiteSVG: WhiteSearchAllIcon,
            selected: isSelected(selected, "all")
        }
    ];

    const renderNavItems = () =>
        navItems.map((item, id) => <NavItem key={id} {...item} />);

    return (
        <div className="cpl-nav">
            <h3>How would you like to conduct your search?</h3>
            <div className="cpl-buttons">{renderNavItems()}</div>
        </div>
    );
};

export default Navigation;
