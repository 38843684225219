import React, { useState } from "react";
import { formatPhoneNumber } from "../../../lib/utils";
import { LocationTypes, ProviderTypes } from "../../../store/providers/types";
import { ReactComponent as ArrowDown } from "../../../assets/icons/findprovider-arrow-down-open.svg";
import { ReactComponent as ArrowUp } from "../../../assets/icons/findprovider-arrow-up-open.svg";

const ProviderDetail: React.FC<ProviderTypes> = ({
    firstName,
    lastName,
    credentials,
    primarySpecialty,
    primaryLocation,
    otherLocations,
    acceptingNewPatients,
    ethnicity,
    race,
    language
}) => {
    return (
        <div className="cpl-provider-detail">
            <span className="cpl-location-strapline">In Network</span>
            <h3>
                {`${firstName}${" "}${lastName}${
                    credentials ? ", " + credentials : ""
                }`}
            </h3>
            <p className="cpl-location-specialty-line">
                <span>Specialty: </span>
                <span>{primarySpecialty}</span>
            </p>
            {acceptingNewPatients && (
                <p className="cpl-location-specialty-line">
                    <span>Accepting New Patients: </span>
                    <span>{acceptingNewPatients}</span>
                </p>
            )}
            {ethnicity && (
                <p className="cpl-location-specialty-line">
                    <span>Ethnicity: </span>
                    <span>{ethnicity}</span>
                </p>
            )}
            {race && (
                <p className="cpl-location-specialty-line">
                    <span>Race: </span>
                    <span>{race}</span>
                </p>
            )}
            {language && (
                <p className="cpl-location-specialty-line">
                    <span>Language: </span>
                    <span>{language}</span>
                </p>
            )}
            <div className="cpl-primary-location">
                <span className="cpl-location-badge">
                    <strong>Primary Location</strong>
                </span>
                <br />
                <span className="cpl-address-line">
                    {primaryLocation.locationName}
                </span>
                <br />
                <span className="cpl-address-line">
                    {primaryLocation.streetAddress}
                </span>
                <br />
                <span className="cpl-address-line">
                    {`${primaryLocation.city}, 
                         ${primaryLocation.state} 
                         ${primaryLocation.zip}`}
                </span>
                <br />
                {primaryLocation.phone && (
                    <span className="cpl-phone-line">
                        Phone:{" "}
                        <span>
                            <a href={`tel:${primaryLocation.phone}`}>
                                {formatPhoneNumber(primaryLocation.phone)}
                            </a>
                        </span>
                    </span>
                )}
                {primaryLocation.fax && (
                    <span className="cpl-phone-line">
                        Fax:
                        <span>{formatPhoneNumber(primaryLocation.fax)}</span>
                    </span>
                )}
            </div>
            {otherLocations.length > 0 && <h4>Additional Locations</h4>}
            {otherLocations.length > 0 &&
                otherLocations.map(ol => {
                    return <AdditionalLocation key={ol.locationName} {...ol} />;
                })}
        </div>
    );
};

export default ProviderDetail;

const AdditionalLocation: React.FC<LocationTypes> = ({
    locationName,
    streetAddress,
    city,
    state,
    zip,
    phone,
    fax
}) => {
    const [visible, setVisible] = useState<boolean>(false);

    const toggleVisible = () => {
        setVisible(!visible);
    };

    return (
        <div className="cpl-additional-location">
            <div className="cpl-additional-location__title">
                <span>{locationName}</span>
                <button type="button" onClick={() => toggleVisible()}>
                    {visible ? "Hide Details" : "Show Details"}
                    {visible ? <ArrowUp /> : <ArrowDown />}
                </button>
            </div>
            {visible && (
                <div className="cpl-additional-location__address">
                    <p className="cpl-address-line">{streetAddress}</p>
                    <p className="cpl-address-line">
                        {city}, {state} {zip}
                    </p>
                    {phone && (
                        <p className="cpl-phone-line">
                            Phone:{" "}
                            <a href={`tel:${phone}`}>
                                {formatPhoneNumber(phone)}
                            </a>
                        </p>
                    )}
                    {fax && (
                        <p className="cpl-phone-line">
                            Fax: {formatPhoneNumber(fax)}{" "}
                        </p>
                    )}
                </div>
            )}
        </div>
    );
};
