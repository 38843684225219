import React from "react";
import { ReactComponent as ArrowDownIcon } from "../../../../assets/icons/findprovider-arrow-down.svg";

type PropTypes = {
    handleClick: (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => void;
    label: string;
    buttonLabel?: string;
};

const ButtonHeader: React.FC<PropTypes> = ({
    handleClick,
    label,
    buttonLabel
}) => {
    return (
        <button className="cpl-filter-dd-button" onClick={handleClick}>
            <span>{label}</span>
            <span>{buttonLabel}</span>
            <span>
                <ArrowDownIcon />
            </span>
        </button>
    );
};

export default ButtonHeader;
