import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router";
import { SelectOption } from "../../../../lib/types";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/storeTypes";
import { ProviderTypes } from "../../../../store/providers/types";
import useClickOutside from "../../../../lib/hooks/useClickOutside";
import { embolden, filterResultsByName } from "../../../../lib/utils";
import useCheckMobileScreen from "../../../../lib/hooks/useCheckMobileScreen";
import { updateActiveFilters } from "../../../../store/filters/actions";
import { ReactComponent as MagnifyingGlass } from "../../../../assets/icons/findprovider-search-magnifying-glass.svg";

const SearchByName: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const isMobile = useCheckMobileScreen();

    // useRef
    const inputRef = useRef<HTMLInputElement>(null);
    const listRefs = useRef([]);
    const labelRef = useRef<HTMLLabelElement>(null);
    const autocomplete = useRef<HTMLDivElement>(null);

    // useState
    const [matches, setMatches] = useState<ProviderTypes[]>([]);
    const [query, setQuery] = useState<string>("");
    const [showError, setShowError] = useState<boolean>(false);
    const [focused, setFocused] = useState<boolean>(false);
    const [selected, setSelected] = useState<boolean>(false);
    const [showAutoComplete, setShowAutoComplete] = useState<boolean>(true);

    // Current State
    const providers = useSelector(
        (state: RootState) => state.providers.providers
    );
    const activeFilters = useSelector(
        (state: RootState) => state.filters.activeFilters
    );

    // Hook implementation
    useEffect(() => {
        inputRef.current.focus();
    }, []);

    useEffect(() => {
        const newMatches = filterResultsByName(providers, query);
        listRefs.current = new Array(newMatches.length);
        setMatches(newMatches);
    }, [query, providers]);

    useClickOutside(autocomplete, () => {
        // if input field is not focused, hide autocomplete
        if (!focused) {
            setShowAutoComplete(false);
        } else {
            setShowAutoComplete(true);
        }
    });

    // Methods
    const handleSubmission = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!query) {
            setShowError(true);
            return;
        }
        const filter: SelectOption = {
            type: "name",
            value: query.trim(),
            label: query,
            isSelected: true
        };

        const updatedFilters = activeFilters
            .filter(ft => ft.type !== "name")
            .concat(filter);
        // Add active filter to state
        dispatch(updateActiveFilters(updatedFilters));
        history.push({
            pathname: "/results",
            state: {
                searchType: "name"
            }
        });
    };

    const handleAutocomplete = value => {
        setQuery(value);
        setShowError(false);
        setShowAutoComplete(false);
        setSelected(true);
        inputRef.current.focus();
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelected(false);
        setQuery(event.target.value);
        if (event.target.value) {
            setShowError(false);
        }
    };

    const handleClick = (firstName: string, lastName: string) => {
        handleAutocomplete(`${firstName} ${lastName}`);
    };

    const focusOnFirstSuggestion = () => {
        if (showAutoComplete && query.length > 1) {
            listRefs.current[0].focus();
        }
    };
    const focusOnLastSuggestion = () => {
        if (showAutoComplete && query.length > 1) {
            listRefs.current[matches.length - 1].focus();
        }
    };

    const focusOnCurrentInput = () => {
        inputRef.current.focus();
        inputRef.current.selectionStart = inputRef.current.value.length;
        inputRef.current.selectionEnd = inputRef.current.value.length;
    };

    const handleKeyDown = (
        event: React.KeyboardEvent<HTMLLIElement>,
        firstName: string,
        lastName: string,
        id: number
    ) => {
        switch (event.key) {
            case "Enter":
                stopPropagation(event);
                handleAutocomplete(`${firstName} ${lastName}`);
                break;
            case "ArrowDown":
                stopPropagation(event);
                if (id === matches.length - 1) {
                    focusOnCurrentInput();
                } else {
                    listRefs.current[id + 1].focus();
                }
                break;
            case "ArrowUp":
                stopPropagation(event);
                if (id === 0) {
                    focusOnCurrentInput();
                } else {
                    listRefs.current[id - 1].focus();
                }
                break;
            default:
                break;
        }
    };

    const handleFocus = () => {
        setFocused(true);
        setShowAutoComplete(true);
        if (isMobile) {
            labelRef.current.scrollIntoView({
                behavior: "smooth"
            });
        }
    };

    const handleBlur = () => {
        setFocused(false);
    };

    const stopPropagation = e => {
        e.preventDefault();
        e.stopPropagation();
    };

    return (
        <div className="cpl-form-wrapper">
            <form className="cpl-search-by-name" onSubmit={handleSubmission}>
                <h3>Providers By Name</h3>
                <label ref={labelRef} htmlFor="searchByName">
                    See if your provider is part of our network by typing their
                    name below.
                </label>
                <div className="cpl-input-wrapper">
                    <input
                        type="text"
                        value={query}
                        id="searchByName"
                        placeholder="Enter first or last name of doctor"
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        ref={inputRef}
                        onChange={handleInputChange}
                        onKeyDown={e => {
                            if (e.key === "ArrowUp") {
                                stopPropagation(e);
                                focusOnLastSuggestion();
                            }
                            if (e.key === "ArrowDown") {
                                stopPropagation(e);
                                focusOnFirstSuggestion();
                            }
                        }}
                    />

                    <button type="submit">
                        <MagnifyingGlass />
                    </button>
                </div>
                {showError && (
                    <p className="cpl-error">
                        Please type in a name to proceed.
                    </p>
                )}
                <p>
                    <small>
                        Not sure about spelling? Type at least two letters and
                        we'll start suggesting names for you.
                    </small>
                </p>
            </form>
            {showAutoComplete && !selected && query && query.length > 1 && (
                <div className="cpl-autocomplete" ref={autocomplete}>
                    <ul className="cpl-autocomplete-list">
                        {matches.length > 0 ? (
                            matches.map(({ firstName, lastName }, id) => {
                                let html = embolden(firstName, lastName, query);

                                return (
                                    <li
                                        key={id}
                                        ref={el => (listRefs.current[id] = el)}
                                        tabIndex={0}
                                        onKeyDown={e =>
                                            handleKeyDown(
                                                e,
                                                firstName,
                                                lastName,
                                                id
                                            )
                                        }
                                        onClick={() =>
                                            handleClick(firstName, lastName)
                                        }
                                        className="cpl-autocomplete-list-item"
                                    >
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: html
                                            }}
                                        />
                                    </li>
                                );
                            })
                        ) : (
                            <li className="cpl-autocomplete-no-results">
                                <span>No matches found. Please try again.</span>
                            </li>
                        )}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default SearchByName;
